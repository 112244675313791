/**
 * Vue shared config
 */
// Vue.config.delimiters = ['{{', '}}'];



/**
 * Services config
 */
const servicesConfig = {
    apiHost: host + "/API",
};



/**
 * Headers config
 */
const headersConfig = {
    emulateJSON: true,
    // 'Kechiq-Shared-Key': 'AnticipaSharedKey',
};
